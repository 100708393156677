import React, { useState, useEffect, useRef } from 'react';
import './Attach.css';
import Dialog from './Dialog';
import DialogOk from './DialogOK';
import Tooltip from './Tooltip';


function Attach(props) {
    const { caseCanceled } = props;

    const [fileToDelete, setFileToDelete] = useState(null);
    const [displayDeleteDialog, setDisplayDeleteDialog] = useState(false);
    const [fileExceed, setfileExceed] = useState(false);
    const [multiplefileExceed, setmultiplefileExceed] = useState(false);
    const [bigFile, setbigFile] = useState(null);
    const [fileAlreadyUploaded, setFileAlreadyUploaded] = useState(false);

    const [uploadedFiles, setUploadedFiles] = useState([])
    const [fileSize, setFileSize] = useState(true);

    const fileInputRef = useRef(null);

    const resetFileInput = () => {
        if (fileInputRef.current) {
            fileInputRef.current.value = '';
        }
    };
    //pushes the uploaded file to the file array if the file is not a duplicate
    const handleUploadFiles = files => {
        const uploaded = [...uploadedFiles];
        files.some((file) => {
            if (uploaded.findIndex((f) => f.name === file.name) === -1) {
                uploaded.push(file);
            } else {
                setFileAlreadyUploaded(true);
            }
        })
        setUploadedFiles(uploaded)
    }

    //deletes the selected file from the uploaded file array and updates the file array
    const confirm = () => {
        setDisplayDeleteDialog(false);

        if (fileToDelete) {
            const updatedFiles = uploadedFiles.filter((file) => file.name !== fileToDelete);

            setUploadedFiles(updatedFiles);
            let sizeExceeded = true;
            let totalSize = 0;

            for (let i = 0; i < updatedFiles.length; i++) {
                totalSize += updatedFiles[i].size;
                if (updatedFiles[i].size > 5000000 || totalSize > 10000000) {
                    sizeExceeded = false;
                    break;
                }
            }
            setFileSize(sizeExceeded);
            props.sendDataToParent(updatedFiles, uploadedFiles, sizeExceeded);
            if (updatedFiles.length === 0) {
                resetFileInput();
            }
        }
    };
    const cancel = () => {
        setFileToDelete(null);
        setDisplayDeleteDialog(false);
    };
    const ok = () => {
        setFileToDelete(null);
        setDisplayDeleteDialog(false);
        setfileExceed(false);
        setmultiplefileExceed(false);
        setFileAlreadyUploaded(false);
    };

    //converts files to binary for use in http requests
    const fileToBinary = async (file) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onload = () => {
                const binaryData = reader.result;
                resolve(binaryData);
            };
            reader.onerror = (error) => {
                reject(error);
            };
            reader.readAsDataURL(file);
        });
    };

    //checks if the files are small enough to upload, and checks if a file has already been uploaded, then sends the files to the parent webpage
    useEffect(() => {
        if (uploadedFiles.length > 0) {
            const convert = async () => {
                const binaryFiles = [];
                for (let i = 0; i < uploadedFiles.length; i++) {
                    const binaryData = await fileToBinary(uploadedFiles[i]);
                    binaryFiles.push(binaryData)
                }
                let sizeExceeded = true
                let totalSize = 0
                for (let i = 0; i < uploadedFiles.length; i++) {
                    totalSize += uploadedFiles[i].size
                    if (uploadedFiles[i].size > 5000000) {
                        sizeExceeded = false;
                        setfileExceed(true)
                        setbigFile(uploadedFiles[i].name);
                        break;
                    } else if (totalSize > 10000000) {
                        sizeExceeded = false;
                        setmultiplefileExceed(true);
                        break;
                    }
                }
                setFileSize(sizeExceeded);
                props.sendDataToParent(binaryFiles, uploadedFiles, sizeExceeded);
            }
            convert()
        }
    }, [uploadedFiles]);
    const handleFileUpload = (e) => {
        const chosenFiles = Array.prototype.slice.call(e.target.files)
        let alreadyUploaded = false;
        chosenFiles.forEach((file) => {
            if (uploadedFiles.findIndex((f) => f.name === file.name) !== -1) {
                console.log("file already uploaded")
                alreadyUploaded = true;
            }
        });
        if (alreadyUploaded) {
            setFileAlreadyUploaded(true);
        } else {
            setFileAlreadyUploaded(false);
            handleUploadFiles(chosenFiles);
        }
        const formData = new FormData();
        for (let i = 0; i < uploadedFiles.length; i++) {
            if (uploadedFiles[i].size > 1024) {
                return;
            }
            formData.append(`uploadedFiles`, uploadedFiles[i])
        }
    }

    //prevents files over 5MB from being uploaded
    const validateSelectedFile = (event) => {
        const files = event.targe.files
        let sizeExceeded = true
        for (let i = 0; i < files.length; i++) {
            if (files[i].size > 5000000) {
                sizeExceeded = false;
                break;
            }
        }
        setFileSize(sizeExceeded);
    };
    const handleDeleteFile = (name, event) => {
        event.preventDefault();
        setFileToDelete(name);
        setDisplayDeleteDialog(true);
    };
    const handleProxyClick = () => {
            fileInputRef.current.click();
    }

    return (
        <div className="upload">
            <h2 className="attachheader">Select files to upload </h2>
            <em className="attachdesc">Individual file maximum upload size is 5MB. Multiple file maximum upload size is 10MB.</em>
            <div className="attachmentsBox">
                <h2 className="attach">Attachments ({uploadedFiles.length})</h2>
            </div>
            <button
                onClick={handleProxyClick}
                id="fileUpload"
                style={{
                    backgroundColor: 'buttonface',
                    border: '1px solid solid',
                    color: 'buttontext',
                    cursor: 'pointer',
                    padding: '1px 4px',
                    borderRadius: '1px',
                }}
            >
                Choose File
            </button>
            <em className="attachdesc">Do not upload <Tooltip text="Sensitive Personal Information" tooltipText="" />.</em>

            <input
                onSelect={validateSelectedFile}
                type="file"
                onChange={handleFileUpload}
                disabled={caseCanceled}
                ref={fileInputRef} 
                style={{ display: 'none' }} 
            />

            {!fileSize && <p style={{ color: 'red' }}>File size exceeded!!</p>}
            <div >
                {uploadedFiles.length === 0 ? (
                        <li className="uploaded-files-none-list">
                        <div className="attlist-container">
                            <p style={{ width: '100%', textAlign: 'center', color: 'lightgray' }}>-</p>
                            </div>
                        </li>

                ) : (
                uploadedFiles.map(file => (
                    <li className="uploaded-files-list">
                        <div className="attlist-container">
                            <p className="attlist"> {file.name}</p>
                            <p className="attlist"> {Math.round(file.size / 1024 * 10) / 10} KB </p>
                        </div>
                        <button className="circular-button delete-button" onClick={(event) => handleDeleteFile(file.name, event)} />
                    </li>
                )))}
            </div>
            <Dialog
                display={displayDeleteDialog}
                title="Delete file"
                confirm={confirm}
                cancel={cancel}
                description={`Do you want delete ${fileToDelete}`}
            />
            <DialogOk
                display={fileExceed}
                title="File Size Exceeded!"
                ok={ok}
                description={`${bigFile} is too big to upload, please delete it before submitting`}
                color="red"
            />
            <DialogOk
                display={multiplefileExceed}
                title="Total File Size Exceeded"
                ok={ok}
                description={`Your total file size is too large`}
                color="red"
            />
            <DialogOk
                display={fileAlreadyUploaded}
                title="File Already Uploaded"
                ok={ok}
                description={`This file has already been uploaded`}
                color="red"
            />
        </div>
    );
}

export default Attach;