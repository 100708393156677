import React, { useState } from 'react';
import './Tooltip.css';

const Tooltip = ({ text }) => {
    const [isHovered, setIsHovered] = useState(false);

    const showTooltip = () => setIsHovered(true);
    const hideTooltip = () => setIsHovered(false);



    return (
        <div className="tooltip-container" onMouseEnter={showTooltip} onMouseLeave={hideTooltip}>
            {text}
            {isHovered && <div className="tooltip-box">
                <div>What is sensitive varies by jurisdiction and regulation.</div>
                In general, however, the term includes information about an individual that is related to:
                <p></p>
                <ul>
                    <li>Race or ethnic origin</li>
                    <li>Political opinions</li>
                    <li>Religious or philosophical beliefs</li>
                    <li>Trade-union membership</li>
                    <li>Sexual orientation</li>
                    <li>Medical and health information, including Protected Health Information (“PHI”) of employees and their covered dependents</li>
                    <li>Social Security number or other government identifiers</li>
                    <li>Biometric records</li>
                    <li>Credit card information, and other financial related information</li>
                </ul>
            </div>}
        </div>
    );
};

export default Tooltip;
